<template>
    <div>
      <a-modal
          :title="modeltitle"
          :visible="visible"

          @ok="uppassOk()"
          @cancel="visible=false"
          style=""

      >
        <a-form-model layout="inline">
          <a-form-model-item label="资源名称"  >
            <a-input style="width: 200px"  v-model="admin.Name" placeholder="资源名称">
            </a-input >
          </a-form-model-item>
          <a-form-model-item label="时长"  >
            <a-input style="width: 200px"  v-model="admin.Duration" placeholder="时长">
            </a-input >
          </a-form-model-item>
          <a-form-model-item label="资源缩略图"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadShowImage"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.ShowImg" placeholder="资源缩略图">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="资源地址"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadImage"

            >
              <a-button>
                <upload-outlined></upload-outlined>
               点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.Url" placeholder="资源地址">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="歌词"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadLrc"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.Lrc" placeholder="歌词">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="背景图片"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadBgImg"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.BgPicture" placeholder="背景图片">
            </a-input >
          </a-form-model-item>
          <a-form-model-item label="内容图片"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadContentImg"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.ContentImg" placeholder="内容图片">
            </a-input >
          </a-form-model-item>
          <a-form-model-item label="内容"  >
            <a-textarea style="width: 380px"   rows="5"  v-model="admin.Contents" placeholder="内容">

            </a-textarea>
          </a-form-model-item>
          <a-form-model-item label="类型"  >
            <a-select  v-model="admin.SType"  style="width: 200px;"   placeholder="选择类型">

              <a-select-option  value="图片">
                图片
              </a-select-option>
              <a-select-option  value="音频">
                音频
              </a-select-option>

            </a-select>


          </a-form-model-item>
          <a-form-model-item label="分类"  >
            <a-select  v-model="admin.ClassId" allowClear style="width: 200px;"   placeholder="选择分类">
              <a-select-option v-for="(item) in classlist" :key="item.ID" :value="item.Id">
                {{item.Name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="企业"  >
            <a-select  v-model="admin.CompanyId" allowClear style="width: 200px;"   placeholder="选择企业">
              <a-select-option v-for="(item) in companylist" :key="item.ID"  :value="item.Id">
                {{item.Name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否为体验区"  >
            <a-select  v-model="admin.TryDay" allowClear style="width: 200px;"   placeholder="是否为体验区">
    
              <a-select-option value="0">
                否
              </a-select-option>
              <a-select-option value="1">
                第一天
              </a-select-option>
              <a-select-option value="2">
                第二天
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="点击次数"  >
            <a-input style="width: 200px"  v-model="admin.ClickNum" placeholder="点击次数">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="分享次数"  >
            <a-input style="width: 200px"  v-model="admin.ShareNum" placeholder="分享次数">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="上线日期"  >

            <a-date-picker v-model="admin.OnlineDate" picker="year" />
          </a-form-model-item>

        </a-form-model>
      </a-modal>
        <a-layout>

            <a-layout style="background: #fff;padding: 0;margin:0;padding: 10px;">
                        <a-form-model layout="inline" :model="queryParam" >

                        <a-row style="margin:16px;">
                          <a-col :span="2">
                            <a-button @click="showModel">新增</a-button>
                          </a-col>
                            <a-col :span="4">

                                <a-form-model-item label="名称" >
                                    <a-input v-model="queryParam.username" placeholder="名称">

                                    </a-input>
                                </a-form-model-item>

                            </a-col>
                          <a-col :span="4">
                          <a-form-model-item label="企业"  >
                            <a-select  v-model="queryParam.CompanyId" allowClear style="width: 200px;"   placeholder="选择企业">
                              <a-select-option v-for="(item) in companylist" :key="item.ID"  :value="item.Id">
                                {{item.Name}}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                          </a-col>
                          <a-col :span="5">
                          <a-form-model-item label="资源分类"  >
                            <a-select  v-model="queryParam.ClassId" allowClear  placeholder="选择分类" style="width: 200px;">
                              <a-select-option v-for="(item) in classlist" :key="item.ID"  :value="item.Id">
                                {{item.Name}}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                          </a-col>
                            <a-col :span="9">
                                <a-form-model-item label="起止时间"  >
                                    <a-range-picker  show-time @change="OnChange">
                                        <a-icon slot="suffixIcon" type="smile" />
                                    </a-range-picker>

                                </a-form-model-item>
                                <a-form-model-item>
                                    <a-button @click="handleSubmit" type="default" icon="search">
                                        搜索
                                    </a-button>
                                </a-form-model-item>

                            </a-col>
                        </a-row>

                        </a-form-model>
            </a-layout>

            <a-layout style="background: #fff;padding: 0;margin:16px 0;padding: 16px;">

                <a-radio-group  style="margin-bottom: 16px">
                    <a-radio-button @click="ChangType('')" value="">
                        全部
                    </a-radio-button>
                    <a-radio-button @click="ChangType('音频')"  value="1">
                        音频
                    </a-radio-button>
                    <a-radio-button @click="ChangType('图片')"  value="2">
                        图片
                    </a-radio-button>
                    <a-radio-button @click="ChangType('早晚安')"  value="3">
                        早晚安
                    </a-radio-button>

                </a-radio-group>
                <a-table size="small"  :rowKey="record => record.Id" :pagination="pagination"   @change="handleTableChange" :columns="columns" :data-source="data" bordered>
                  <template slot="action" slot-scope="text, record">
                    <a-space>
                                <span>
                          <a @click="editclomun(record.Id)">编辑</a>
                        </span>
                      <span>
                          <a @click="deleteclomun(record.Id)">删除</a>
                        </span>
                    </a-space>
                  </template>
                </a-table>
            </a-layout>
        </a-layout>
    </div>

</template>

<script>

import { UploadOutlined } from '@ant-design/icons-vue';
    export default {
        name: "index",
        components: {
    UploadOutlined,
  },
        data(){
            return {

              fileList:[
          ],
              modeltitle:'',
              visible:false,
                pagination: {
                    total: 0,
                    pageSize: 18,//每页中显示10条数据
                    showSizeChanger: true,
                    pageSizeOptions: ["18", "20", "50", "100"],//每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
                },
              admin:{},
                topsize:'large',
              classlist:[],
              companylist:[],
                queryParam:{
                    page:1,
                    size:18,
                    username:'',
                  CompanyId:null,
                  ClassId:null,
                    SType:'',
                    startdate:null,
                    enddate:null,

                },
                columns:[
                    {
                        title: '名称',
                        dataIndex: 'Name'

                    },
                    {
                        title: '地址',
                        dataIndex: 'Url',

                    },
                    {
                        title: '分类名称',

                        dataIndex: 'ClassName'

                    },
                    {
                        title: '创建人',

                        dataIndex: 'Creater',


                    },
                    {
                        title: '资源类型',
                        dataIndex: 'SType'

                    },
                  {
                    title: '点击次数',
                    dataIndex: 'ClickNum'

                  },
           
                    {
                        title: '发布时间',
                        dataIndex: 'CreateTime'

                    },
                  {
                    title: '上线日期',
                    dataIndex: 'OnlineDate'

                  },

                  {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots:{ customRender: 'action' }

                  }
                ],
                data:[]

            }
        },

        created() {

        },
        mounted(){


            this.GetPageList('');
            this.InitCompanys();
            this.InitClasses();
        },
        methods:{
          uploadImage(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'Url',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
          },
          uploadShowImage(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'ShowImg',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });

},uploadLrc(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'Lrc',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
  },uploadBgImg(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'BgPicture',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
},
uploadContentImg(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'ContentImg',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
},
          showModel(){
            this.visible=true;this.modeltitle='新增';this.admin={};
          },
          InitClasses(){
            let that=this;
            that.companylist=[];
            this.$axios.post("/api/SourceDown/GetSourceClassList", null).then((res) => {

              if (res.data.message=="success"){

                that.classlist = res.data.data;

              }

            });
          },
          InitCompanys(){
            let that=this;
            that.companylist=[];
            this.$axios.post("/api/SourceDown/GetCompanyList", null).then((res) => {
              if (res.data.message=="success"){
                that.companylist = res.data.data;
              }
            });
          },

            ChangType(t)
            {
                this.queryParam.page=1;
                this.pagination.current=0;
                this.GetPageList(t);

            },
            handleSubmit(){
                this.GetPageList('');
            },

            OnChange(date,dateString){
           if (dateString.length>0)

            this.queryParam.startdate=dateString[0]?this.$moment(dateString[0]).format('YYYY-MM-DD'):'';
           this.queryParam.enddate=dateString[1]?this.$moment(dateString[1]).format('YYYY-MM-DD'):'';
            },
          OnlineChange(date,dateString){
            if (dateString.length>0)
              this.admin.OnlineDate=dateString[0]?this.$moment(dateString[0]).format('YYYY-MM-DD'):'';
          },
            handleTableChange(pagination){
                this.pagination.current = pagination.current;
                this.pagination.pageSize = pagination.pageSize;
                this.queryParam.page = pagination.current;
                this.queryParam.size = pagination.pageSize;
                this.GetPageList();
            },
            GetPageList(type){
                let that=this;
                that.data=[];
                that.pagination.total=0;
                    this.queryParam.SType=type;
                this.$axios.post("/api/SourceDown/GetSourceDownList", that.queryParam).then((res) => {
                    if (res.data.message=="success"){
                       that.pagination.total = res.data.data.Total;
                        that.data = res.data.data.Data;

                    }

                });

            },
          editclomun(id){
            let that=this;


              that.admin={};
              that.modeltitle='修改';
              that.visible=true;
              that.$axios.post("/api/SourceDown/GetSourceDown", {Id:id}).then((res) => {
                if (res.data.message=='success'){
                  that.admin=res.data.data;
                }

              });

          },
          deleteclomun(id){
            let that=this;

            that.$axios.post("/api/SourceDown/DeleteSourceDown", {Id:id}).then((res) => {
              if (res.data.message=='success'){
                that.data=that.data.filter((item)=>{return item.Id!=id});
                that.$message.success('删除成功!');

              }else{
                that.$message.error('删除失败!');
              }
            });


          },
          uppassOk(){
            let that=this;
            let url;
            if (that.modeltitle=='新增'){
              url="/api/SourceDown/AddSourceDown";
            }else{
              url="/api/SourceDown/UpdateSourceDown";
            }
            that.$set(that.admin,'Creater',that.$store.getters.User.UserName)
            that.$axios.post(url, that.admin).then((res) => {
              if (res.data.message=='success'){
                this.visible=false;
                that.$message.success('操作成功!');
                that.GetPageList();

              }else{
                that.$message.error('操作失败!');
              }
            });
          }
        }
    }
</script>

<style scoped>

</style>